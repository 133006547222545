// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-polozka-js": () => import("./../src/pages/polozka.js" /* webpackChunkName: "component---src-pages-polozka-js" */),
  "component---src-pages-profil-js": () => import("./../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-podmienky-pouzivania-md": () => import("./../src/pages/podmienky-pouzivania.md" /* webpackChunkName: "component---src-pages-podmienky-pouzivania-md" */),
  "component---src-pages-gdpr-md": () => import("./../src/pages/gdpr.md" /* webpackChunkName: "component---src-pages-gdpr-md" */),
  "component---src-pages-projekt-md": () => import("./../src/pages/projekt.md" /* webpackChunkName: "component---src-pages-projekt-md" */)
}

